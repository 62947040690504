/*-------------aub-page-css----------*/
.sbanner_section{
  background: url(../assets/bckimg1.png) no-repeat;
  background-size: cover;
  min-height: 100vh;
  height: 100vh;
  padding: 0;
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
}
.sbanner_content{
  max-width: 500px;
  margin: auto;
  margin-top: -70px;
}
.sbanner_content h2, .sbanner_content p{
  text-align: center;
}
.details_form{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 0 15px;
    align-items: center;
    background: #ffffff;
    padding: 30px;
    box-shadow: 0 5px 40px 0 rgb(0 0 0 / 11%);
    border-radius: 16px;
}
.details_form .form-control{
    background-color: #e2eefa;
    height: 40px;
    line-height: 40px;
}
.details_form  input[type="submit"] {
    background: #086ad8;
}
.details_form h4{
    color: #086ad8;
    font-size: 20px;
    line-height: 30px;
    margin: 0;
}
.details_form h4 span{
    font-size: 14px;
    line-height: 24px;
    color: #000;
}
.ratings ul{
    margin: 30px 0 0;
    padding: 0;
}
.ratings ul li{
    position: relative;
    padding-left: 30px;
    font-size: 16px;
    margin-bottom: 10px;
}
.ratings ul li i{
    position: absolute;
    left: 0;
    color: #086ad8;
    top: 4px;
}

/*------------intro-section-css-------------*/
.intro_section{
    padding: 0 0 60px;
    position: relative;
    z-index: 1;
    overflow: hidden;
    background-color: #ffffff;
}
.intro_section .row{
  align-items: center;
}
.intro_section img{
  padding-right: 40px;
}

/*------benifits-section-css-----------*/

.benifits_section{
    background-color: #e2eefa;
    padding: 60px 0;
}
.benifits_section .row{
  align-items: center;
  margin-bottom: 30px;
}
.benifits_section .row:last-child{
  margin-bottom: 0;
}
.benifits_section .section-title h2{
  max-width: 502px;
  margin-left: auto;
  margin-right: auto;
}

.registration_section{
  padding: 60px 0;
  position: relative;
  background-color: #ffffff;
}
.registration_section .row{
  justify-content: center;
}
.registration_section .section-title h2{
  max-width: 450px;
  margin-left: auto;
  margin-right: auto;
}
.registration_section .col .content{
  background: #ffffff;
  border-radius: 1rem;
  padding: 1.4rem 1rem;
  box-shadow: 0px 0px 25px 10px rgb(0 0 0 / 20%);
  text-align: center;
  min-height: 250px;
  margin-bottom: 30px;
}
.registration_section .iconbox{
  background: rgba(8, 106, 216, .11);
  width: 60px;
  height: 60px;
  border-radius: 24px 0 24px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 1rem;
}
.registration_section .iconbox i{
  color: #000000;
  font-size: 24px;
}
.registration_section .content h2{
  font-size: 17px;
  line-height: 24px;
  font-weight: 600;
  margin-bottom: 5px;
  color: #000000;
}
.registration_section .content p{
  font-size: 14px;
  line-height: 22px;
  margin: 0;
}
.registration_section .row .col:hover .iconbox{
  background: #086ad8;
} 
.registration_section .row .col:hover i{
  color: #ffffff;
}

/*----------process-section-css--------*/
.the_process{
  background-color: #e2eefa;
  padding: 60px 0;
}
.the_process .row{
  align-items: center;
}
.the_process .content{
  margin-bottom: 30px;
  position: relative;
}
.the_process .content::before{
  content: '';
  position: absolute;
  left: 18px;
  top: 22px;
  height: 100%;
  border: 1px dashed #ffffff;
}
.the_process .content:last-child::before,
.the_process .content:nth-child(2)::before{
  border: none;
  display: none;
  opacity: 0;
}
.the_process .steps_heading{
  position: relative;
  display: flex;
  align-items: center;
  padding-left: 52px;
  margin-bottom: 20px;
} 
.the_process .steps_heading .icon{
  position: absolute;
  left: 0;
  background: #ffffff;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-top-left-radius: 12px;
  border-bottom-right-radius: 12px;
}
.the_process .steps_heading i{
  text-align: center;
}
.the_process .steps_heading h4{
  margin-bottom: 0;
}
.the_process img{
  border-radius: 8px;
}
.the_process .section-title{
  margin-bottom: 60px;
}
.the_process ul li{
  list-style: disc;
  min-width: 114px;
}

.the_process .react-tabs__tab-panel ul{
  max-width: 394px;
  margin: auto;
}

.sub-apge.faq_section{
  padding-top: 60px;
}

.appointmentPage.subcatpage-data .details_form{
  display: block;
}
.appointmentPage.subcatpage-data .details_form .grids{
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0 15px;
  align-items: center;
}
.appointmentPage .intro_section img{
  max-width: 400px;
}
.appointmentPage .row{
  margin-bottom: 30px;
}
.appointmentPage .row:nth-child(2) img{
 float: right;
}

.the_process_steps{
  background-color: #e2eefa;
  padding: 60px 0;
}
.the_process_steps .left_block{
  padding-right: 60px;
}
.the_process_steps .right_block{
  padding-left: 60px;
  border-left: 1px solid #ffffff;
}
.the_process_steps .content{
  margin-bottom: 30px;
  position: relative;
}
.the_process_steps .content::before{
  content: '';
  position: absolute;
  left: 18px;
  top: 22px;
  height: 100%;
  border: 1px dashed #ffffff;
}
.the_process_steps .content:last-child::before{
  border: none;
  display: none;
  opacity: 0;
}
.the_process_steps .steps_heading{
  position: relative;
  display: flex;
  align-items: center;
  padding-left: 52px;
  margin-bottom: 20px;
} 
.the_process_steps .steps_heading .step, 
.the_process_steps .steps_heading .icon{
  position: absolute;
  left: 0;
  background: #ffffff;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-top-left-radius: 12px;
  border-bottom-right-radius: 12px;
}
.the_process_steps .steps_heading h4{
  margin-bottom: 0;
  text-align: center;
}
.the_process_steps .section-title{
  margin-bottom: 60px;
}
.the_process_steps .steps_heading i{
  text-align: center;
}
.the_process_steps .section-title h2 {
  text-align: left;
}
.the_process_steps .section-title .bar{
  margin-left: 0;
}
.the_process_steps ul{
  padding-left: 67px;
}
.the_process_steps ul li{
  list-style: disc;
}
.the_process_steps p span{
  font-size: 14px;
}
.registration_section.patent_search .col .content{
  height: auto;
}

.ct_form{
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: 15px;
  margin-top: 40px;
}
.ct_form .form-control {
width: 100%;
height: 52px;
padding: 0 15px;
line-height: 52px;
color: #000000;
background-color: #ffffff;
border: none;
outline: none;
border-radius: 0;
-webkit-box-shadow: unset !important;
box-shadow: unset !important;
-webkit-transition: .5s;
transition: .5s;
font-size: 15px;
font-weight: 400;
border-radius: 4px;
margin-bottom: 0;
box-sizing: border-box;
}

.ct_form input[type="submit"] {
  background: #086ad8;
  color: #ffffff;
  height: 52px;
  line-height: 52px;
  padding: 0 15px;
  font-weight: 600;
  border-radius: 4px;
  margin: 0;
}
.ct_form input[type="submit"]:hover{
background: #000000;
}

.plans_section{
  padding: 0 0 60px;
  position: relative;
  background-color: #ffffff;
}
.plans_section .section-title h2{
  max-width: 450px;
  margin-left: auto;
  margin-right: auto;
}
.plans_section .col .content{
  background: #ffffff;
  border-radius: 1rem;
  padding: 1.4rem 1rem;
  box-shadow: 0px 0px 25px 10px rgb(0 0 0 / 20%);
  text-align: center;
  height: 296px;
  margin-bottom: 30px;
}
.plans_section .iconbox{
  background: rgba(8, 106, 216, .11);
  width: 60px;
  height: 60px;
  border-radius: 24px 0 24px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 1rem;
}
.plans_section .iconbox i{
  color: #000000;
  font-size: 24px;
}
.plans_section .content h2{
  font-size: 17px;
  line-height: 24px;
  font-weight: 600;
  margin-bottom: 5px;
  color: #000000;
}
.plans_section .content p{
  font-size: 14px;
  line-height: 22px;
  margin: 0;
}
.plans_section .row .col:hover .iconbox{
  background: #086ad8;
} 
.plans_section .row .col:hover i{
  color: #ffffff;
}
.plans_section .content li{
  font-size: 14px;
  line-height: 22px;
  margin: 0;
}
.react-tabs__tab-list{
  text-align: center;
  border-bottom: none !important;
}
.react-tabs__tab{
  border-bottom: 1px solid #aaa !important;
  margin-bottom: 15px;
}
.react-tabs__tab:focus:after{
  height: 0;
  opacity: 0;
}
.react-tabs__tab-list{
  padding-left: 0 !important;
  margin: 0 0 40px !important;
}
.height_auto{
  min-height: auto !important;
}
.list_bullets{
  list-style: disc;
  padding-left: 15px;
}
.list_bullets li{
  list-style: disc;
}
.margin60{
  margin-top: 60px;
}
.align-items-start{
  align-items: self-start !important;
}
.list-style-disc, .list-style-disc li{
  list-style-type: disc;
}
.list-style-disc{
  padding-left: 15px;
}
.text-center{
  text-align: center;
}

/*---------------media-query---------------*/
@media (max-width:767px){
  .sbanner_section{
    height: 100%;
  }
  .sbanner_content{
    margin-top: 0;
    padding-top: 100px;
  }
  .details_form{
    grid-template-columns: 1fr;
    max-width: 450px;
    margin-left: auto;
    margin-right: auto;
  }
  .details_form h4{
    margin-bottom: 15px;
  }
  .appointmentPage.subcatpage-data .details_form .grids{
    grid-template-columns: 1fr;
  }
  .intro_section{
    padding: 40px 0;
  }
  .intro_section img{
    margin-bottom: 30px;
  }
  .benifits_section .row:nth-child(2n){
    flex-direction: row-reverse; 
  }
  .the_process img, .faq_section img{
    margin-top: 30px;
  }
  .appointmentPage .row:nth-child(2){
    flex-direction: row-reverse;
  }
  .appointmentPage .row:nth-child(2) img{
    float: none;
   }
   .appointmentPage .intro_section img{
     max-width: 100%;
   }
   .the_process_steps .left_block{
     padding-right: 15px;
   }
   .the_process_steps .right_block{
    padding-left: 15px;
    border-left: none;
  }
  .the_process .content:nth-child(2)::before{
    border: 1px dashed #ffffff;
    display: block;
    opacity: 1;
  }
}
@media (min-width:1023px) and (max-width:1199px){
  .registration_section .col .content{
    height: 260px;
  }
}