/*------------------------contact-page-css------------------------------*/

.contact-section{
  position: relative;
  padding: 0 0 60px;
}
.single-contact-box {
    text-align: center;
    background-color: #fff;
    -webkit-box-shadow: 0 0 11px 3px rgba(0, 0, 0, .05);
    box-shadow: 0 0 11px 3px rgba(0, 0, 0, .05);
    padding: 30px;
    margin-bottom: 30px;
    min-height: auto;
  }
  .single-contact-box:last-child{
    margin-bottom: 0;
  }
  
  .single-contact-box i {
    line-height: 50px;
    width: 50px;
    height: 50px;
    border: 1px dashed #086ad8;
    border-radius: 50%;
    font-size: 20px;
    display: inline-block;
    color: #086ad8;
    -webkit-transition: all .5s;
    transition: all .5s;
    margin-bottom: 25px
  }
  
  .single-contact-box:hover i {
    background-color: #086ad8;
    color: #fff
  }
  
  .single-contact-box .content-title h3 {
    font-size: 25px;
    margin-bottom: 10px
  }
  
  .single-contact-box .content-title p {
    margin: 0
  }
  
  .single-contact-box .content-title a {
    display: block;
    font-size: 16px;
    margin-bottom: 5px;
    color: #000000;
  }
  
  .single-contact-box .content-title a:hover {
    color: #086ad8;
  }
  .contact-text {
    margin-bottom: 30px
  }
  
  .contact-section h3 {
    margin-bottom: 10px;
    font-size: 30px; 
    text-align: center;
  }
  
  .contact-form {
    background-color: #fff;
    -webkit-box-shadow: 0 5px 40px 0 rgba(0, 0, 0, .11);
    box-shadow: 0 5px 40px 0 rgba(0, 0, 0, .11);
    padding: 35px
  }
  
  .contact-form form .form-group {
    margin-bottom: 15px
  }
  
  .contact-form form .form-group label {
    margin-bottom: 10px;
    font-weight: 600
  }
  .contact-form form .form-control {
      height: 42px;
      padding: 0 15px;
      line-height: 42px;
      color: #212121;
      background-color: transparent;
      border: 1px solid #e5e0e0;
      border-radius: 0;
      -webkit-box-shadow: unset !important;
      box-shadow: unset !important;
      -webkit-transition: .5s;
      transition: .5s;
      font-size: 15px;
      font-weight: 400;
      border-radius: 4px;
  }
  
  .contact-form form .form-control:focus {
    border-color: #086ad8
  }
  
  .contact-form form textarea.form-control {
    height: auto!important;
    padding-top: 15px;
    max-height: 216px; 
  }
  
  .contact-form .send-btn {
    margin-top: 20px;
    -webkit-transition: .5s;
    transition: .5s
  }
  
  .contact-form .send-btn .default-btn {
    border: none;
    display: inline-block;
    width: 100%;
    cursor: pointer;
    background: #086ad8;
    color: #ffffff;
    height: 52px;
    line-height: 52px;
    padding: 0 15px;
    font-weight: 600;
    border-radius: 4px;
    margin: 0;
  }
  
  .contact-form .with-errors ul {
    padding-left: 0;
    list-style-type: none;
    margin-top: 5px;
    margin-bottom: 0
  }
  
  .contact-form .with-errors ul li {
    color: red
  }
  
  .contact-form #msgSubmit {
    margin-bottom: 0;
    font-size: 22px;
    font-weight: 500
  }
  
  .contact-form #msgSubmit.text-danger {
    margin-top: 20px!important;
    font-family: Open Sans, sans-serif
  }
  
  .contact-section .map-section{
      padding-left: 40px;
  }