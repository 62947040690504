.signup-page{
    height: 100vh;
    background: #e2eefa;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .signup-form{
    max-width: 800px;
    margin: auto;
    background: #ffffff;
    border-radius: 8px;
    padding: 40px;
    text-align: center;
  }
  .signup-form input {
    background-color: #e2eefa;
    border: none;
    border-radius: 0;
    box-shadow: unset!important;
    box-sizing: border-box;
    color: #000;
    font-size: 15px;
    font-weight: 400;
    margin-bottom: 0;
    outline: none;
    transition: .5s;
    width: 100%;
    height: 40px;
    line-height: 40px;
    margin-bottom: 15px;
    padding: 0 15px;
  }
  .signup-form form{
    margin-top: 30px;
  }
  .signup-form .form-group.options{
    display: flex;
    align-items: center;
    margin-bottom: 15px;
  }
  .signup-form .form-group.options span{
    padding-left: 10px;
  }
  .signup-form .form-group.options p{
    display: flex;
    align-items: center;
  }
  .signup-form .form-group.options p span{
    padding-left: 10px;
  }
  .signup-form input[type="checkbox"]{
    width: 20px;
    height: 20px;
    line-height: 20px;
    padding: 0;
    margin: 0;
  }
  .signup-form a{
    color: #086ad8;
  }
  .signup-form input[type="submit"]{
    background: #086ad8;
    color: #ffffff;
    margin: 20px 0;
    font-size: 16px;
    font-weight: 600;
  }
  @media (max-width: 480px){
    .signup-form{
      padding: 20px 15px;
    }
  }