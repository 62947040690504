/*--------------category-page-css----------*/
.sub-banner-section{
    background: url(../assets/bckimg1.png) no-repeat;
    background-size: cover;
    background-position: bottom;
    min-height: 400px;
    padding: 0;
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
  }
  .sub-banner-section .row{
    display: block;
    margin-top: -6rem;
  }
  .banner-content{
    max-width: 650px;
    margin: auto;
    margin-top: -60px;
  }
  .banner-content h2{
    font-size: 32px;
    line-height: 40px;
    color: #000000;
    text-align: center;
    max-width: 450px;
    margin: 0 auto;
  }
  .banner-content h2 span{
    color: #086ad8;
  }
  .banner-content p{
    font-size: 16px;
    line-height: 24px;
    color: #ffffff;
  }

  /*-------------cat-section-css------------*/
.cat_section{
    padding: 0 0 40px;
    position: relative;
    z-index: 1;
    overflow: hidden;
    margin-top: -24px;
  }
.cat_section img{
    max-height: 450px;
  }

/*---------------popular-options-css----------------*/
.popular-options{
    padding: 60px 0;
    background-color: #e2eefa;
    position: relative;
  }
  .section-title {
    text-align: center;
    margin-bottom: 60px;
  }
  .section-title .bar{
    margin-left: auto;
    margin-right: auto;
  }
  .popular-options .col .single-services {
      text-align: center;
      background-color: #fff;
      padding: 30px;
      border-radius: 10px;
      position: relative;
      z-index: 1;
      -webkit-box-shadow: 0 5px 40px 0 rgba(0, 0, 0, .11);
      box-shadow: 0 5px 40px 0 rgba(0, 0, 0, .11);
    margin-bottom: 30px;
    height: 415px;
  }
  
  .single-services .icon i {
      display: inline-block;
      height: 70px;
      width: 90px;
      line-height: 70px;
      background-color: rgba(8, 106, 216, .11);
      text-align: center;
      font-size: 30px;
      color: #000000;
      border-radius: 30px 5px 30px 5px
  }
  
  .single-services h3 {
      font-size: 20px;
      margin: 25px 0 10px;
      -webkit-transition: .5s;
      transition: .5s
  }
  
  .single-services p {
      margin-bottom: 0;
      -webkit-transition: .5s;
      transition: .5s;
      font-size: 14px;
  }
  
  .single-services .get-started-btn{
     margin-left: auto;
     margin-right: auto;
  }
  
  .popular-options .col .single-services:before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      z-index: -1;
      width: 100%;
      height: 0%;
      background: #086ad8;
      border-radius: 0;
      -webkit-transition: .5s;
      transition: .5s;
      border-radius: 10px
  }
  
  .popular-options .col .single-services:hover {
      -webkit-box-shadow: 0 5px 40px 0 rgba(0, 0, 0, .11);
      box-shadow: 0 5px 40px 0 rgba(0, 0, 0, .11)
  }
  
  .popular-options .col .single-services:hover:before {
      height: 100%
  }
  
  .popular-options .col .single-services:hover .read-btn,
  .popular-options .col .single-services:hover h3,
  .popular-options .col .single-services:hover p,
  .popular-options .col .single-services:hover i {
      color: #fff
  }
  .popular-options .col .single-services:hover i{
    background-color: rgba(255, 255, 255, .11);
  }
  .popular-options .col .single-services:hover .get-started-btn,
  .popular-options .col .single-services:hover .get-started-btn:hover,
  .popular-options .col .single-services:hover .get-started-btn:focus{
    background: #000000;
  }

  .special-business{
      background-color: #ffffff;
      padding: 60px 0;
      position: relative;
  }
  .special-business .row{
    align-items: center;
    margin-bottom: 40px;
  }
  .special-business img{
    max-height: 300px;
  }

/*----------faq-section-css-----------*/
.faq_section{
    padding: 0 0 60px;
    position: relative;
}
.accordion{
    border: none !important;
}
.faq_section .accordion__button{
    background-color: #e2eefa;
    border: 1px solid #e2eefa;
    margin-bottom: 10px;
    padding: 15px 10px;
    padding-left: 30px;
    position: relative;
}
.faq_section .accordion__button:hover{
    background-color: #ffffff;
}
.faq_section .accordion__panel{
    border: 1px solid #e2eefa;
    margin-top: -10px;
    margin-bottom: 10px;
}
.faq_section .accordion__button:before{
  position: absolute;
  left: 10px;
  top: 22px;
}

@media (min-width:1023px) and (max-width:1199px){
  .popular-options .col .single-services{
    height: 440px;
  }
}
  