/*-----------common-css----------*/
*, ::after, ::before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
html{
  overflow-x: hidden;
}
body{
  overflow-x: hidden;
  box-sizing: border-box;
  font-family: 'Montserrat', sans-serif;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
}
textarea{
  font-family: 'Montserrat', sans-serif;
}
h1, h2, h3, h4, h5, h6, p, span, a, li, input{
  font-family: 'Montserrat', sans-serif;
}
h1, h2, h3, h4, h5, h6{
  margin: 0 0 15px;
}
h2{
  font-size: 24px;
  line-height: 34px;
  font-weight: 600;
}
p{
  font-size: 16px;
  line-height: 24px;
}
a{
  cursor: pointer;
  text-decoration: none;
}
img{
  max-width: 100%;;
}
ul{
  padding: 0;
}
ul li{
  list-style: none;
}
.container{
  width: 100%;
  margin: auto;
  padding: 0 20px;
}
.row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}
.col{
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}
.col1 {
  -ms-flex: 0 0 8.333333%;
  flex: 0 0 8.333333%;
  max-width: 8.333333%;
}
.col2 {
  -ms-flex: 0 0 16.666667%;
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
}
.col3 {
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
}
.col4 {
  -ms-flex: 0 0 33.333333%;
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}
.col5 {
  -ms-flex: 0 0 41.666667%;
  flex: 0 0 41.666667%;
  max-width: 41.666667%;
}
.col6 {
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}
.col7 {
  -ms-flex: 0 0 58.333333%;
  flex: 0 0 58.333333%;
  max-width: 58.333333%;
}
.col8 {
  -ms-flex: 0 0 66.666667%;
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
}
.col9 {
  -ms-flex: 0 0 75%;
  flex: 0 0 75%;
  max-width: 75%;
}
.col11 {
  -ms-flex: 0 0 91.666667%;
  flex: 0 0 91.666667%;
  max-width: 91.666667%;
}
.col12 {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}
.cmn_btn{
  background-color: #086ad8;
  min-width: 6rem;
  max-width: 8rem;
  padding: 0 1rem;
  height: 3rem;
  line-height: 3rem;
  color: #ffffff;
  text-align: center;
  border-radius: 20px 0 20px 0;
  display: block;
  font-weight: 600;
  margin-top: 30px;
}
.cmn_btn:hover, .cmn_btn:focus{
  background: #000000;
}
.bar {
  height: 4px;
  width: 85px;
  background: #086ad8; 
  position: relative;
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 16px
}

.bar:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 5px;
  background: #fff;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-name: MOVE-BG;
  animation-name: MOVE-BG
}
@-webkit-keyframes MOVE-BG {
	0% {
		-webkit-transform: translateX(0)
	}
	to {
		-webkit-transform: translateX(85px)
	}
}

@keyframes MOVE-BG {
	0% {
		-webkit-transform: translateX(0);
		transform: translateX(0)
	}
	to {
		-webkit-transform: translateX(85px);
		transform: translateX(85px)
	}
}

.default-shape {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
}
.square-shape{
  position: absolute;
  left: 1%;
  top: 8%;
}
.square-shape img{
  animation: scale 2s alternate infinite;
}
@keyframes scale {
  0% {
    transform: scale(0.8);
    -webkit-transform: scale(0.8);
  }
  100% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}
.circle-shape{
  position: absolute;
  right: 6%;
  bottom: 4%;
}
.circle-shape img{
  animation: dance 4s alternate infinite;
}
@keyframes dance {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
  }
  50% {
    -webkit-transform: translate3d(15px, -15px, 0);
  }
  100% {
    -webkit-transform: translate3d(0, -15px, 15px);
  }
}
.tri-shape{
  position: absolute;
  bottom: 30%;
  left: 10%;
}
.tri-shape img{
  max-height: 160px;
  animation: up-down 7s alternate infinite;
  -webkit-animation: up-down 7s alternate infinite;
}
@keyframes up-down {
  0% {
    transform: translateY(20px);
    -webkit-transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
    -webkit-transform: translateY(-20px);
  }
  100% {
    transform: translateY(0);
    -webkit-transform: translateY(0);
  }
}
@-webkit-keyframes up-down {
  0% {
    transform: translateY(30px);
    -webkit-transform: translateY(0);
  }
  50% {
    transform: translateY(-50px);
    -webkit-transform: translateY(-50px);
  }
  100% {
    transform: translateY(0);
    -webkit-transform: translateY(0);
  }
}
.rect-shape{
  position: absolute;
  right: 15%;
  top: 16%;
}
.rect-shape img{
  animation: mymove 5s infinite;
}
@keyframes mymove {
  0% {
    transform: rotateY(-50deg);
  }
  100% {
    transform: rotateY(50deg);
  }
}
@-webkit-keyframes mymove { 
  0% {
    transform: rotateY(-50deg);
  }
  100% {
    transform: rotateY(50deg);
  }
}
.form-control {
  width: 100%;
  height: 52px;
  padding: 0 15px;
  line-height: 52px;
  color: #000000;
  background-color: #ffffff;
  border: none;
  outline: none;
  border-radius: 0;
  box-shadow: unset !important;
  transition: .5s;
  font-size: 15px;
  font-weight: 400;
  border-radius: 4px;
  margin-bottom: 15px;
  box-sizing: border-box;
}

input[type="submit"] {
  background: #086ad8;
  color: #ffffff;
  height: 52px;
  line-height: 52px;
  padding: 0 15px;
  font-weight: 600;
  border-radius: 4px;
  margin: 0;
  cursor: pointer;
}
.coming-soon{
  padding: 60px 0;
}
/*-----------header-css----------*/

header{
  background-color: transparent;
  position: absolute;
  width: 100%;
  z-index: 9;
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 72px;
}
.site-logo{
  width: 17%
}
.navbar{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav-links{
  display: flex;
  justify-content: flex-end;
  list-style: none;
  /* position: relative; */
}
.navbar a:hover,
.navbar a:focus{
  color: #000;
}
.mobile-menu-icon{
  display: none;
}
.nav-links li{
  position: unset;
  padding: 15px 0;
}
.navbar a{
  color: #000;
  text-decoration: none;
  font-size: 12px;
  line-height: 16px;
  padding: 0 8px;
  text-align: left;
}
.submenu{
  box-shadow: 0 0 15px 0 rgb(0 0 0 / 10%);
  opacity: 0;
  visibility: hidden;
  position: absolute;
  background-color: #fff;
  padding: 0;
  border-radius: 4px;
  top: 60px;
  left: 0;
  width: 100%;
  min-width: 350px;
  z-index: 9;
  max-height: 90vh;
  overflow-y: auto;
}
.submenu .submenu_container{
  width: 100%;
  max-width: 1140px;
  margin: auto;
  padding: 0 15px;
}
.submenu .sublist{
  padding: 30px 15px;
}
.submenu .sublist h4{
  margin-bottom: 10px;
}
.submenu li{
  padding: 0;
}
.submenu li a{
  display: block;
  padding: 5px 0;
}
.submenu li a:hover,
.submenu li a:focus{
  color: #086ad8;
}
.nav-links li:hover .submenu,
.nav-links li:focus .submenu{
  opacity: 1;
  visibility: visible;
  transition: all 0.5s ease-out;
}
.site-header .menu-right ul{
  display: flex;
  align-items: center;
}
.site-header .menu-right .login_btn{
  padding: 10px;
  border: 1px solid #086ad8;
  border-radius: 4px;
}
.site-header .menu-right li i{
  font-size: 18px;
  margin-right: 10px;
}

/*------------banner-section-css---------*/

.banner-section{
  background: url(./assets/bckimg1.png) no-repeat;
  background-size: cover;
  min-height: 100vh;
  height: 100vh;
  padding: 0;
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
}
.banner-section .row{
  display: block;
  margin-top: -6rem;
}
.banner-content{
  max-width: 650px;
  margin: auto;
}
.banner-content h2{
  font-size: 32px;
  line-height: 40px;
  color: #000000;
  text-align: center;
  max-width: 450px;
  margin: 0 auto;
}
.banner-content h2 span{
  color: #086ad8;
}
.banner-content p{
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
}
.search_form{
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: 15px;
  margin-top: 40px;
}
.search_form .form-control {
width: 100%;
height: 52px;
padding: 0 15px;
line-height: 52px;
color: #000000;
background-color: #ffffff;
border: none;
outline: none;
border-radius: 0;
-webkit-box-shadow: unset !important;
box-shadow: unset !important;
-webkit-transition: .5s;
transition: .5s;
font-size: 15px;
font-weight: 400;
border-radius: 4px;
margin-bottom: 0;
box-sizing: border-box;
}

.search_form input[type="submit"] {
  background: #086ad8;
  color: #ffffff;
  height: 52px;
  line-height: 52px;
  padding: 0 15px;
  font-weight: 600;
  border-radius: 4px;
  margin: 0;
}
.search_form input[type="submit"]:hover{
background: #000000;
}



/*----------served-section-css---------------*/
.served_section{
   position: relative;
   z-index: 1;
}
.served_section .title h2{
  font-size: 1.5em;
  margin-bottom: 60px;
  text-align: center;
  line-height: 1.2em;
}
.served_section .col{
  margin-bottom: 30px;
}
.served_section .col .content{
  background: #ffffff;
  border-radius: 1rem;
  padding: 1.4rem 1rem;
  box-shadow: 0px 0px 25px 10px rgb(0 0 0 / 20%);
  text-align: center;
  height: 216px;
}
.served_section .iconbox{
  background: rgba(8, 106, 216, .11);
  width: 60px;
  height: 60px;
  border-radius: 24px 0 24px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 1rem;
}
.served_section .iconbox i{
  color: #000000;
  font-size: 24px;
}
.served_section h2{
  font-size: 17px;
  line-height: 24px;
  font-weight: 600;
  margin-bottom: 5px;
  color: #000000;
}
.served_section p{
  font-size: 14px;
  line-height: 22px;
  margin: 0;
}
.served_section .row .col:hover .iconbox{
  background: #086ad8;
} 
.served_section .row .col:hover i{
  color: #ffffff;
}

/*-------------about-section-css------------*/
.about_section{
  padding: 100px 0 0;
  position: relative;
  z-index: 1;
  overflow: hidden;
}
.about_section img{
  max-height: 450px;
}

/*---------------services-section-css----------------*/
.services_section{
  padding: 200px 0 60px;
  background-color: #ffffff;
  background-image: url(./assets/bckshape.png);
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
}
.services_section .row{
  padding-top: 60px;
}
.section-title {
  text-align: center;
  margin-bottom: 60px;
}
.section-title .bar{
  margin-left: auto;
  margin-right: auto;
}
.services_section .col .single-services {
	text-align: center;
	background-color: #fff;
	padding: 30px;
	border-radius: 10px;
	position: relative;
	z-index: 1;
	-webkit-box-shadow: 0 5px 40px 0 rgba(0, 0, 0, .11);
	box-shadow: 0 5px 40px 0 rgba(0, 0, 0, .11);
  margin-bottom: 30px;
  height: 415px;
}
.services_section .section-title{
  margin-top: -120px;
}

.single-services .icon i {
	display: inline-block;
	height: 70px;
	width: 90px;
	line-height: 70px;
	background-color: rgba(8, 106, 216, .11);
	text-align: center;
	font-size: 30px;
	color: #000000;
	border-radius: 30px 5px 30px 5px
}

.single-services h3 {
	font-size: 20px;
	margin: 25px 0 10px;
	-webkit-transition: .5s;
	transition: .5s
}

.single-services p {
	margin-bottom: 0;
	-webkit-transition: .5s;
	transition: .5s;
	font-size: 14px;
}

.single-services .get-started-btn{
   margin-left: auto;
   margin-right: auto;
}

.services_section .col .single-services:before {
	content: "";
	position: absolute;
	left: 0;
	top: 0;
	z-index: -1;
	width: 100%;
	height: 0%;
	background: #086ad8;
	border-radius: 0;
	-webkit-transition: .5s;
	transition: .5s;
	border-radius: 10px
}

.services_section .col .single-services:hover {
	-webkit-box-shadow: 0 5px 40px 0 rgba(0, 0, 0, .11);
	box-shadow: 0 5px 40px 0 rgba(0, 0, 0, .11)
}

.services_section .col .single-services:hover:before {
	height: 100%
}

.services_section .col .single-services:hover .read-btn,
.services_section .col .single-services:hover h3,
.services_section .col .single-services:hover p,
.services_section .col .single-services:hover i {
	color: #fff
}
.services_section .col .single-services:hover i{
  background-color: rgba(255, 255, 255, .11);
}
.services_section .col .single-services:hover .get-started-btn,
.services_section .col .single-services:hover .get-started-btn:hover,
.services_section .col .single-services:hover .get-started-btn:focus{
  background: #000000;
}

/*---------footer.css---------*/

.footer-section {
	background-color: #000000
}

.subscribe-area {
	background: url(./assets/counter-bg.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  padding: 60px 0;
}
.subscribe-area .row{
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 20px;
  align-items: center;
}

.subscribe-area .subscribe-content h2 {
	font-size: 25px;
	margin-bottom: 10px;
  color: #ffffff;
}
.subscribe-area .subscribe-content p{
  color: #ffffff;
  margin: 0;
}
.subscribe-area .newsletter-form {
	max-width: 580px;
	position: relative;
	margin-left: auto;
	margin-right: auto
}

.subscribe-area .newsletter-form .input-newsletter {
	display: block;
	width: 100%;
	background-color: #fff;
	border: none;
	height: 70px;
	padding-left: 25px;
	border-radius: 10px;
	outline: 0;
	color: #000000;
  box-sizing: border-box;
}

.subscribe-area .newsletter-form .input-newsletter::-webkit-input-placeholder {
	color: #666
}

.subscribe-area .newsletter-form .input-newsletter:-ms-input-placeholder {
	color: #666
}

.subscribe-area .newsletter-form .input-newsletter::-moz-placeholder {
	color: #666
}

.subscribe-area .newsletter-form .input-newsletter::placeholder {
	color: #666
}

.subscribe-area .newsletter-form button {
	position: absolute;
	right: 4px;
	top: 4px;
	background-color: #000000;
	color: #fff;
	border: none;
	height: 62px;
	padding: 0 30px;
	border-radius: 10px;
	-webkit-transition: .5s;
	transition: .5s;
	line-height: 50px;
	text-transform: capitalize;
	cursor: pointer;
	font-size: 16px;
	font-weight: 400;
  box-sizing: border-box;
}

.subscribe-area .newsletter-form button:hover {
	background-color: #000000;
	color: #ffffff;
}

.ft-grids{
  background: #212121;
  padding: 60px 0 20px;
  color: #ffffff;
}
.ft-grids .row{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 15px;
}
.single-footer-widget {
	margin-bottom: 30px
}

.single-footer-widget .footer-heading {
	margin-bottom: 20px
}

.single-footer-widget .footer-heading h3 {
	font-size: 18px;
  font-weight: 600;
  line-height: 24px;
}

.single-footer-widget .footer-social {
	padding-left: 0;
	margin-bottom: 0
}

.single-footer-widget .footer-social li {
	display: inline-block;
	margin-right: 10px;
	list-style-type: none
}

.single-footer-widget .footer-social li:last-child {
	margin-right: 0
}

.single-footer-widget .footer-social i {
	display: inline-block;
	height: 35px;
	width: 35px;
	line-height: 35px;
	background-color: #086ad8;
	color: #fff;
	text-align: center;
	border-radius: 30px;
	font-size: 14px;
	-webkit-transition: .5s;
	transition: .5s
}

.single-footer-widget .footer-social i:hover {
	background-color: #10dce8
}

.single-footer-widget .footer-quick-links {
	padding-left: 0;
	margin-bottom: 0
}

.single-footer-widget .footer-quick-links li {
	list-style-type: none;
	margin-bottom: 12px;
	font-size: 15px;
	font-weight: 600
}

.single-footer-widget .footer-quick-links li:last-child {
	margin-bottom: 0
}
.single-footer-widget a{
  color: #ffffff;
  font-size: 14px;
  line-height: 20px;
  text-decoration: none;
  font-weight: normal;
}
.single-footer-widget a:hover,
.single-footer-widget a:focus{
  color: #086ad8;
}
.single-footer-widget .footer-info-contact{
  position: relative;
  padding-left: 40px;
  margin-bottom: 16px;
}
.single-footer-widget .footer-info-contact i {
	position: absolute;
	left: 0;
  top: 2px;
	font-size: 20px;
}

.single-footer-widget .footer-info-contact h3 {
	margin-bottom: 5px;
	font-size: 15px;
	font-weight: 600;
  color: #ffffff;
}

.single-footer-widget .footer-info-contact span {
	font-size: 15px;
  color: #ffffff;
}

.copyright_text p{
  margin-bottom: 0;
  border-top: 1px solid rgba(255,255,255,.3);
  padding-top: 22px;
  text-align: center;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
}

/*---our-partners-css----*/
.our_partners{
  padding: 0 0 60px;
}
.our_partners .section-title{
  margin-bottom: 40px;
}
.our_partners .item{
  padding: 0 10px;
}
.our_partners .item img{
  max-height: 80px;
  border: 1px solid #ebebeb;
  object-fit: contain;
}
.our_partners .owl-dot span{
  background: rgba(0, 0, 0, .11) !important;
}
.our_partners .owl-dot.active span{
  background: #000 !important;
}

/*----testimonial-slider-css-----*/
.testimonial_slider{
  padding: 60px 0;
  position: relative;
}
.testimonial_slider .circle-shape{
  bottom: -4%;
}
.testimonial_slider .item .clients-item {
  background: rgba(8, 106, 216, .11);
  border-radius: 1rem;
  padding: 1.4rem 1rem;
  text-align: center;
}
.testimonial_slider .item i{
  font-size: 40px;
  margin-bottom: 20px;
}
.testimonial_slider .item p {
  font-size: 16px;
  line-height: 24px;
}
.testimonial_slider h4 {
  font-size: 18px;
  line-height: 26px;
  font-weight: 600;
}
.testimonial_slider .owl-dot span{
  background: rgba(0, 0, 0, .11) !important;
  margin-top: 40px !important; 
}
.testimonial_slider .owl-dot.active span{
  background: #000 !important;
}

/*-------------------media-query-css------------*/

@media (max-width:576px){
  .site-logo{
    width: 45%
  }
  .search_form{
    grid-template-columns: 1fr;
  }
  .banner-content h2 {
    font-size: 28px;
    line-height: 38px;
  }
  .served_section{
    margin-top: 0;
  }
  .served_section .row .col{
    margin-bottom: 25px;
  }
  .rect-shape{
    left: auto;
    right: 0;
  }
  .banner-section{
    height: 100%;
    padding-top: 100px;
  }
  .banner-content{
    margin-top: 0;
  }
  .about_section .col:first-child, 
  .moreinfo_section .col:first-child{
    margin-bottom: 30px;
  }
  .about_section, .services_section, .moreinfo_section{
    padding: 40px 0;
  }
  .circle-shape{
    left: auto;
    right: 2%;
  }
  .services_section{
    background-image: none;
  }
  .moreinfo_section .square-shape{
    width: 90px;
    top: 3%;
  }
  .moreinfo_section .circle-shape{
    width: 90px;
    bottom: 1%;
  }
  .partners_section ul{
    display: block;
  }
  .partners_section ul li{
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 15px;
  }
  .partners_section{
    padding-bottom: 40px;
  }
  .subscribe-area .subscribe-content{
    margin-bottom: 30px;
  }
  .subscribe-area .newsletter-form .input-newsletter{
    padding-left: 15px;
  }
  .subscribe-area .newsletter-form button{
    padding: 0 15px;
  }
  .ft-grids{
    padding-top: 40px;
  }
  .served_section .title h2{
    margin-bottom: 40px;
  }
  .services_section .section-title {
    margin-top: 0;
    margin-bottom: 0;
  }
  .services_section{
    padding-top: 0;
  }
  .services_section .row{
    padding-top: 40px;
  }
  .testimonial_slider .default-shape{
    display: none;
  }
  .testimonial_slider{
    padding-top: 0;
  }
  .default-shape{
    display: none;
  }
  .served_section .col .content, .services_section .col .single-services{
    height: 100%;
  }
  .services_section .col{
    margin-bottom: 30px;
  }
  .row{
    display: block !important;
  }
  .site-header .menu-right{
    padding-right: 40px;
  }
  .site-header .menu-right .login_btn{
    padding: 10px 5px;
  }
  .site-header .menu-right li i{
    margin-right: 0;
  }
  .site-header .menu-right li i{
    font-size: 14px;
  }
}

@media (min-width:577px) and (max-width:767px){ 
  .served_section .row{
    grid-template-columns: 1fr 1fr;
  }
  .about_section .row{
    display: block;
  }
  .about_section img{
    margin-bottom: 40px;
  }
  .services_section .row{
    padding-top: 0;
    grid-template-columns: 1fr 1fr;
  }
  .default-shape{
    display: none;
  }
  .subscribe-area .row{
    display: block;
  }
  .subscribe-area .row .col:first-child{
    margin-bottom: 30px;
  }
  .ft-grids .row{
    grid-template-columns: 1fr 1fr;
  }
  .site-header .menu-right{
    padding-right: 60px;
  }
}

@media (max-width:767px){
  .col {
    -ms-flex: 0 0 100% !important;
    flex: 0 0 100% !important;
    max-width: 100% !important; 
  }
  .subscribe-content{
    margin-bottom: 20px;
    text-align: center;
  }
}
@media (min-width:767px) and (max-width:1023px){
  .col {
    -ms-flex: 0 0 50% !important;
    flex: 0 0 50% !important;
    max-width: 50% !important; 
  }
  .col.col12 {
    -ms-flex: 0 0 100% !important;
    flex: 0 0 100% !important;
    max-width: 100% !important; 
  }
  .subscribe-area .row{
    display: block;
  }
  .subscribe-content{
    margin-bottom: 20px;
    text-align: center;
  }
  .subscribe-area .col, .ft-grids .col {
    -ms-flex: 0 0 100% !important;
    flex: 0 0 100% !important;
    max-width: 100% !important; 
  }
  .ft-grids .row{
    grid-gap: 0;
  }
  .site-header .menu-right{
    padding-right: 60px;
  }
}
@media (min-width:1023px) and (max-width:1199px){
  .served_section .col .content{
    height: 234px;
  }
  .services_section .col .single-services{
    height: 460px;
  }
  .site-header .menu-right{
    padding-right: 60px;
  }
}

@media screen and (max-width: 1199px){
  .nav-links{
    opacity: 0;
    visibility: hidden;
    display: none;
  }
  .nav-links-mobile{
    position: absolute;
    opacity: 1;
    visibility: visible;
    list-style: none;
    background-color: #e2eefa;
    left: 0;
    top: 72px;
    transition: all 0.5s ease-out;
    width: 100%;
    height: 100vh;
    padding: 0 0 20px;
    margin: 0;
    z-index: 8;
    overflow-y: scroll;
  }
  .mobile-menu-icon{
    display: block;
    position: absolute;
    font-size: 30px;
    color: #000000;
    background-color: transparent;
    border: none;
    outline: none;
    top: 20px;
    right: 15px;
    z-index: 9;
  }
  .nav-links-mobile
   a{
    padding: 10px 15px;
    width: 100%;
    display: block;
  }
  .nav-links-mobile .submenu{
    position: relative;
    opacity: 1;
    visibility: visible;
    box-shadow: none;
    background: transparent;
    border-radius: 0;
    min-width: 100%;
    top: 0;
    max-height: 100%;
  }
  .submenu li a{
    padding: 10px 15px; 
  }
  .submenu li:first-child a{
    padding-top: 10px; 
  }
  .submenu li:last-child a{
    padding-bottom: 10px;
  }
  .submenu .sublist {
    padding: 5px 15px;
  }
  .submenu .sublist li a{
    padding-left: 0;
    padding-right: 0;
  }
}

.tabsSteps {
  height: 170px;
}

@media (max-width:767px){
  .tabsSteps {
    height: 320px;
  }
}