.forgot-password-page{
    height: 100vh;
    background: #e2eefa;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .forgot-password-form{
    max-width: 500px;
    margin: auto;
    background: #ffffff;
    border-radius: 8px;
    padding: 40px;
    text-align: center;
  }
  .forgot-password-form h2{
    margin-bottom: 15px;
  }
  .forgot-password-form input {
    background-color: #e2eefa;
    border: none;
    border-radius: 0;
    box-shadow: unset!important;
    box-sizing: border-box;
    color: #000;
    font-size: 15px;
    font-weight: 400;
    margin-bottom: 0;
    outline: none;
    transition: .5s;
    width: 100%;
    height: 40px;
    line-height: 40px;
    margin-bottom: 15px;
    padding: 0 15px;
  }
  .forgot-password-form form{
    margin-top: 30px;
  }
  .forgot-password-form a{
    color: #086ad8;
  }
  .forgot-password-form input[type="submit"]{
    background: #086ad8;
    color: #ffffff;
    margin: 20px 0;
    font-size: 16px;
    font-weight: 600;
  }
  @media (max-width: 480px){
    .forgot-password-form{
      padding: 20px 15px;
    } 
  }